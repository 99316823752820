import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Map from "../components/Map";
import PingMarker from "../components/PingMarker";

import { useAppContext } from "../common/AppContext";

const PAGE_SIZE = 100;
const MAX_PINGS = 5000; // Hard limit on # of pings shown

const getPings = async (userId, startTs, endTs) => {
  const queryParams = { start_ts: startTs, end_ts: endTs, limit: PAGE_SIZE };
  // Paginate through response
  let morePages = true;
  let cursor = null;
  let ret = [];
  while (morePages === true) {
    const params = {
      queryStringParameters: cursor ? { ...queryParams, cursor } : queryParams,
    };
    const resp = await API.get("sparo", `/pings/v1/user/${userId}`, params);
    ret = [...ret, ...resp.items];
    cursor = resp?.cursor;
    if (!cursor || ret.length >= MAX_PINGS) break;
  }
  return ret;
};

export default function ViewPings() {
  const {
    user: { username },
  } = useAppContext();

  // Date picker
  const [startDate, setStartDate] = useState(new Date("2020-11-14"));
  const [endDate, setEndDate] = useState(new Date("2020-11-15"));

  // Load pings
  const [loading, setLoading] = useState(true);
  const [pings, setPings] = useState([]);
  useEffect(() => {
    setLoading(true);

    // Convert date range to unix ts for query
    // Date range is inclusive of both dates
    const startTs = Math.round(startDate.getTime() / 1000);
    const endTs = Math.round(endDate.setHours(23, 59, 59) / 1000);

    // Query
    getPings(username, startTs, endTs)
      .then((pings) => {
        setLoading(false);
        setPings(pings);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error getting pings:", e);
      });
  }, [username, startDate, endDate]);

  // Calculate ping bounds for map
  const lats = pings.map((ping) => ping.latitude);
  const lons = pings.map((ping) => ping.longitude);
  const bounds =
    pings.length > 0
      ? {
          east: Math.max(...lons),
          west: Math.min(...lons),
          north: Math.max(...lats),
          south: Math.min(...lats),
        }
      : null;

  return (
    <div className="h-100 d-flex flex-column">
      <h4 className="border-bottom pb-2 mb-2">View Pings</h4>
      {/* Date range */}
      <div className="d-flex flex-row align-items-center my-1">
        Date range:
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="ml-2"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="ml-2"
        />
      </div>
      <div className="my-1">{pings.length} recorded pings</div>
      {/* Map */}
      <div className="flex-grow-1 mt-1 mb-3 position-relative">
        <Map bounds={bounds}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100 avoid-clicks">
              <Spinner animation="border" />
            </div>
          ) : (
            pings.map((ping, idx) => (
              <PingMarker
                key={idx}
                lat={ping.latitude}
                lon={ping.longitude}
                heading={ping.heading}
              />
            ))
          )}
        </Map>
      </div>
    </div>
  );
}
