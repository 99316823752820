import React, { Component } from "react";

export default class HomePage extends Component {
  render() {
    return (
      <>
        <h1>Home Page</h1>
      </>
    );
  }
}
