import React from "react";
import { Switch, Route } from "react-router-dom";
import RouteAuth from "./components/RouteAuth";
import RouteNoAuth from "./components/RouteNoAuth";

// Website content
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
// Users - login/signup/etc
import Login from "./user/Login";
import Signup from "./user/Signup";
import Profile from "./user/Profile";
import ResetPassword from "./user/ResetPassword";
import ChangePassword from "./user/ChangePassword";
// Diagnostic - view pings
import ViewPings from "./pings/ViewPings";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      {/* Users - public - login/signup/etc */}
      <RouteNoAuth path="/login" title="Login">
        <Login />
      </RouteNoAuth>
      <RouteNoAuth path="/signup" title="Sign Up">
        <Signup />
      </RouteNoAuth>
      <RouteNoAuth path="/reset_password" title="Reset Password">
        <ResetPassword />
      </RouteNoAuth>
      {/* Diagnostic - view pings */}
      <RouteAuth path="/pings" title="Pings" exact>
        <ViewPings />
      </RouteAuth>
      {/* Users - private - manage account */}
      <RouteAuth path="/profile" title="Profile" exact>
        <Profile />
      </RouteAuth>
      <RouteAuth path="/profile/password" title="Change Password">
        <ChangePassword />
      </RouteAuth>
      {/* Not found */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
