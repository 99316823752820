import React from "react";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FormField({
  name,
  type,
  label = null,
  placeholder,
  icon,
  autoFocus = false,
  showError = true,
  ...props
}) {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon={icon} className="fa-w-16" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          autoFocus={autoFocus}
          as={Form.Control}
          {...props}
        />
      </InputGroup>
      {showError && (
        <span className="text-danger small">
          <ErrorMessage name={name} />
        </span>
      )}
    </Form.Group>
  );
}
