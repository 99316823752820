// Auth flow form to login existing user

import React from "react";
import { NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import LoaderButton from "../components/LoaderButton";
import FormField from "../components/FormField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
});

export default function LoginForm({ handleSubmit, handleSocial, errorMsg }) {
  return (
    <Row className="justify-content-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Login</h4>
        <div className="mt-2 mb-4">
          Login to manage your account, and view analytics:
        </div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validation}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="email"
                type="email"
                placeholder="Email address"
                icon={faEnvelope}
                autoFocus={true}
              />
              <FormField
                name="password"
                type="password"
                placeholder="Password"
                icon={faLock}
              />
              <LoaderButton
                type="submit"
                size="lg"
                block
                isLoading={isSubmitting}
              >
                Login
              </LoaderButton>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </Form>
          )}
        </Formik>
        <NavLink to="reset_password" className="nav-link p-0 mt-4">
          Forgot password?
        </NavLink>

        {/* Horizontal rule */}
        <Row className="my-3">
          <Col>
            <hr />
          </Col>
          <Col className="col-auto p-1">
            <span>OR</span>
          </Col>
          <Col>
            <hr />
          </Col>
        </Row>

        {/* Social login */}
        <Row className="justify-content-center mb-3">
          <Button
            size="lg"
            className="btn-google"
            onClick={() => handleSocial("Google")}
          >
            <img src="social/google_logo.svg" alt="" className="logo-google" />
            <span className="align-middle">Continue with Google</span>
          </Button>
        </Row>
        <Row className="justify-content-center mb-3">
          <Button
            size="lg"
            className="btn-facebook"
            onClick={() => handleSocial("Facebook")}
          >
            <img src="social/fb_logo.png" alt="" className="logo-facebook" />
            <span className="align-middle">Continue with Facebook</span>
          </Button>
        </Row>
      </Col>
    </Row>
  );
}
