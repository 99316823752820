import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import AppLayout from "./layouts/AppLayout";
import { AppContext } from "./common/AppContext";
import Routes from "./Routes";

export default function App() {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Load existing auth session, if exists
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((respUser) => {
        setUser(respUser);
        setLoading(false);
      })
      .catch((e) => {
        if (e !== "not authenticated") console.error(e);
        setLoading(false);
      });
  }, []);

  return (
    !isLoading && (
      <AppContext.Provider value={{ user, setUser }}>
        <AppLayout>
          <Routes />
        </AppLayout>
      </AppContext.Provider>
    )
  );
}
