// Routes only for un-authenticated users. If authenticated, redirect to home.

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../common/AppContext";

export default function RouteNoAuth({ children, title, ...props }) {
  const { user } = useAppContext();
  useEffect(() => {
    document.title = title ? `Sparo | ${title}` : "Sparo";
  }, [title]);
  return !user ? <Route {...props}>{children}</Route> : <Redirect to="/" />;
}
