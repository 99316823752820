// Auth flow form to complete new password (if admin-created account)

import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormField from "../components/FormField";
import LoaderButton from "../components/LoaderButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  name: Yup.string().required("Please provide your name"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  password2: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default function NewPasswordForm({ handleSubmit, errorMsg }) {
  return (
    <Row className="justify-content-md-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Set New Password</h4>
        <div className="mt-2 mb-4">
          As your account was created for you, please set a new password.
        </div>
        <Formik
          initialValues={{ name: "", password: "", password2: "" }}
          validationSchema={validation}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="name"
                type="text"
                placeholder="Name"
                icon={faUser}
              />
              <FormField
                name="password"
                type="password"
                placeholder="Password"
                icon={faLock}
              />
              <FormField
                name="password2"
                type="password"
                placeholder="Confirm password"
                icon={faLock}
              />
              <LoaderButton
                type="submit"
                size="lg"
                block
                isLoading={isSubmitting}
              >
                Set New Password
              </LoaderButton>
              {errorMsg && <span className="text-danger">{errorMsg}</span>}
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
