// Google Maps circle marker with label
// https://stackoverflow.com/a/43855288/11610924

import React from "react";
import { Marker } from "@react-google-maps/api";

import { colors } from "../config/colors";

export default function PingMarker({ lat, lon, heading, ...props }) {
  const iconPath =
    heading === -1
      ? window.google.maps.SymbolPath.CIRCLE
      : window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
  const icon = {
    path: iconPath,
    rotation: heading,
    fillOpacity: 1,
    fillColor: "#fff",
    strokeOpacity: 1,
    strokeWeight: 2,
    strokeColor: colors.palette.primaryColor,
    scale: 5,
  };

  return (
    <Marker position={{ lat: lat, lng: lon }} icon={icon} {...props}></Marker>
  );
}
