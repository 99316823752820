import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { useAppContext } from "../common/AppContext";
import LoginForm from "./LoginForm";
import ConfirmCodeForm from "./ConfirmCodeForm";
import NewPasswordForm from "./NewPasswordForm";

export default function Login() {
  const { user, setUser } = useAppContext();
  const [challengeUser, setChallengeUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const history = useHistory();
  const alert = useAlert();

  // Catch Cognito error on 1st social signup, with existing native account
  // https://forums.aws.amazon.com/thread.jspa?threadID=267154
  // https://blog.ilearnaws.com/2020/08/06/error-cognito-auth-flow-fails-with-already-found-an-entry-for-username/
  const hash = window.location.hash;
  if (hash) {
    // Get social provider
    const regex = /#error_description=Already\+found\+an\+entry\+for\+username\+([a-z]{6,8})_/;
    const provider = hash.match(regex)?.[1];
    if (provider === "google") Auth.federatedSignIn({ provider: "Google" });
    if (provider === "facebook") Auth.federatedSignIn({ provider: "Facebook" });
  }

  // Login with email/password
  const handleLogin = (values, { setSubmitting }) => {
    setErrorMsg(null);
    Auth.signIn(values.email, values.password)
      .then((respUser) => {
        setSubmitting(false);
        if (respUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          // User needs to change password
          setChallengeUser(respUser);
          setShowNewPassword(true);
        } else {
          // Authenticated successfully
          setUser(respUser); // CognitoUser
          history.push("/");
          alert.success("Logged in");
        }
      })
      .catch((e) => {
        if (e.code === "UserNotConfirmedException") {
          // User not confirmed - show ConfirmCodeForm
          setUserDetails({ ...values, username: values.email });
          setShowConfirmCode(true);
        } else {
          setErrorMsg(e.message);
          setSubmitting(false);
          console.error(e);
        }
      });
  };

  // Social login
  const handleSocial = (provider) => Auth.federatedSignIn({ provider });

  // New password form (for admin-created accounts - set new password on login)
  const handleNewPassword = (values, { setSubmitting }) => {
    if (user === null) {
      console.error("No user to set new password for");
      return;
    }
    setErrorMsg(null);
    Auth.completeNewPassword(challengeUser, values.password, {
      name: values.name,
    })
      .then((respUser) => {
        // Authenticated successfully
        setUser(respUser);
        history.push("/");
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  if (showConfirmCode) {
    return <ConfirmCodeForm userDetails={userDetails} />;
  } else if (showNewPassword) {
    return (
      <NewPasswordForm handleSubmit={handleNewPassword} errorMsg={errorMsg} />
    );
  } else {
    return (
      <LoginForm
        handleSubmit={handleLogin}
        handleSocial={handleSocial}
        errorMsg={errorMsg}
      />
    );
  }
}
