import React, { useState } from "react";
import { Auth } from "aws-amplify";

import SignupForm from "./SignupForm";
import ConfirmCodeForm from "./ConfirmCodeForm";

export default function Signup() {
  const [userDetails, setUserDetails] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [codeDetails, setCodeDetails] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSignup = (values, { setSubmitting }) => {
    setErrorMsg(null);
    Auth.signUp({
      username: values.email,
      password: values.password,
      attributes: { name: values.name },
    })
      .then((resp) => {
        if (resp.userConfirmed === false) {
          // User not confirmed - show ConfirmCodeForm
          setUserDetails({ ...values, username: resp.user.username });
          setCodeDetails(resp.codeDeliveryDetails);
          setErrorMsg(null);
          setShowConfirm(true);
        } else {
          console.warn("User already confirmed at signup");
        }
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  // TODO redirect if logged in
  return showConfirm & (codeDetails !== null) ? (
    <ConfirmCodeForm userDetails={userDetails} codeDetails={codeDetails} />
  ) : (
    <SignupForm handleSubmit={handleSignup} errorMsg={errorMsg} />
  );
}
