// User profile page

import React from "react";
import { NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

export default function ProfileLayout(props) {
  return (
    <Row>
      <Col xl={3} lg={4} md={5} sm={12} className="mr-4">
        <div className="mb-4 p-3 border rounded shadow-sm">
          <h5 className="mb-3">Account</h5>
          <Nav variant="pills" className="flex-column">
            <NavLink to="/profile" exact className="nav-link">
              User Profile
            </NavLink>
            <NavLink to="/profile/password" exact className="nav-link">
              Change Password
            </NavLink>
            {/* <NavLink to="/profile/social" exact className="nav-link">
              Social Accounts
            </NavLink> */}
          </Nav>
        </div>
      </Col>
      <Col>{props.children}</Col>
    </Row>
  );
}
