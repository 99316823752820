// Auth flow form to login existing user

import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { useAppContext } from "../common/AppContext";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoaderButton from "../components/LoaderButton";
import FormField from "../components/FormField";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  code: Yup.number().typeError("Code is a number").required("Required"),
});

export default function ConfirmForm({ userDetails }) {
  const { setUser } = useAppContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();
  const alert = useAlert();

  const handleConfirmCode = (values, { setSubmitting }) => {
    setErrorMsg(null);
    if (!userDetails || !userDetails.username) {
      console.error("No user to confirm code");
      return;
    }
    Auth.confirmSignUp(userDetails.username, values.code)
      .then(() => {
        // Confirmed account - authenticate user
        setSubmitting(false);
        Auth.signIn(userDetails.username, userDetails.password)
          .then((respUser) => {
            setUser(respUser); // CognitoUser
            history.push("/");
            alert.success("Confirmed your account!");
          })
          .catch((e) => {
            // Could still be an incorrect username/password
            setErrorMsg(e.message);
            setSubmitting(false);
            console.error(e);
          });
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  // Resend confirmation code to user
  const handleResendCode = () => {
    if (!userDetails || !userDetails.username) {
      console.error("No user to confirm code");
      return;
    }
    Auth.resendSignUp(userDetails.username)
      .then((resp) => {
        const { Destination } = resp.CodeDeliveryDetails;
        alert.info(`Account confirmation code sent to ${Destination}.`);
      })
      .catch((e) => {
        setErrorMsg(e.message);
        console.error(e);
      });
  };

  return (
    <Row className="justify-content-md-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Confirm your account</h4>
        <div className="mt-2 mb-4">
          Please check your email for a confirmation code, and enter it below:
        </div>
        <Formik
          initialValues={{ code: "" }}
          validationSchema={validation}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleConfirmCode}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="code"
                type="text"
                placeholder="Confirmation code"
                icon={faLock}
                autoFocus={true}
              />
              <LoaderButton
                type="submit"
                size="lg"
                block
                isLoading={isSubmitting}
              >
                Confirm Account
              </LoaderButton>
              <Nav.Link className="p-0 mt-2" onClick={handleResendCode}>
                Request a new code
              </Nav.Link>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
