// Logout

import React from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { useAppContext } from "../common/AppContext";

export default function Logout({ as: Component, children, ...props }) {
  const history = useHistory();
  const { setUser } = useAppContext();
  const alert = useAlert();

  const handleLogout = () => {
    Auth.signOut()
      .then(() => {
        setUser(null);
        history.push("/");
        alert.success("Logged out");
      })
      .catch((e) => console.error(e));
  };

  return (
    <Component onClick={handleLogout} {...props}>
      {children}
    </Component>
  );
}
