// Manage user profile attributes

import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useAlert } from "react-alert";
import { useAppContext } from "../common/AppContext";

import ProfileLayout from "./ProfileLayout";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoaderButton from "../components/LoaderButton";
import FormField from "../components/FormField";
import { faEnvelope, faUser, faPhone } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
});

export default function Profile() {
  const { user, setUser } = useAppContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const alert = useAlert();

  // DIAGNOSTIC - print user token
  Auth.currentAuthenticatedUser().then((resp) =>
    console.log(resp.signInUserSession.getIdToken().getJwtToken())
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const newAttrib = { name: values.name, phone_number: values.phone };
    Auth.updateUserAttributes(user, newAttrib)
      .then(() => {
        alert.success("Updated your profile");
        Auth.currentAuthenticatedUser().then((respUser) => setUser(respUser));
        setErrorMsg(null);
        setSubmitting(false);
      })
      .catch((e) => {
        setErrorMsg(e.message);
        console.error(e);
      });
  };

  return (
    <ProfileLayout>
      <h4 className="border-bottom pb-2 mb-4">User Profile</h4>
      <Formik
        initialValues={{
          name: user?.attributes?.name || "",
          email: user?.attributes?.email || "",
          phone: user?.attributes?.phone_number || "",
        }}
        validationSchema={validation}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormField
              name="name"
              type="text"
              label="Name"
              placeholder="Name"
              icon={faUser}
            />
            <FormField
              name="email"
              type="email"
              label="Email address"
              placeholder="Email address"
              readOnly
              icon={faEnvelope}
            />
            <FormField
              name="phone"
              type="text"
              label="Phone number"
              placeholder="Phone number"
              icon={faPhone}
            />
            <LoaderButton
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              className="mt-3"
            >
              Update Details
            </LoaderButton>
            {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
          </Form>
        )}
      </Formik>
    </ProfileLayout>
  );
}
