import { Auth } from "aws-amplify";

// TODO envvars - dev vs prod
export const AMPLIFY_CONFIG = {
  Auth: {
    region: "eu-west-2",
    userPoolId: "eu-west-2_VdsGCsZ7I",
    userPoolWebClientId: "2ujvjg6ecqjh8h0v2cbosuu83q",
    oauth: {
      domain: "auth.dev.sparo.io",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://dev.sparo.io/login",
      redirectSignOut: "https://dev.sparo.io",
      responseType: "token",
    },
  },
  API: {
    endpoints: [
      {
        name: "sparo",
        endpoint: "https://api.dev.sparo.io",
        custom_header: async () => {
          // Must use ID token not access token, for API gateway authorizer
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyAENgpHsH6Vsb_BhUSq5XyiZ7VQtMdDLTg";
