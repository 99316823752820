import React from "react";
import { positions, transitions } from "react-alert";
import BsAlert from "react-bootstrap/Alert";

export const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 2500,
  containerStyle: { zIndex: 100, marginTop: "56px", padding: "20px" },
  transition: transitions.FADE,
};

export default function Alert({ options, message, close }) {
  const alertType = options.type.replace("error", "danger");
  return (
    <BsAlert
      variant={alertType}
      onClose={close}
      dismissible
      style={{ pointerEvents: "all" }}
    >
      {message}
    </BsAlert>
  );
}
