import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { useAppContext } from "../common/AppContext";
import {
  ResetPasswordRequestForm,
  ResetPasswordConfirmForm,
} from "./ResetPasswordForm";

export default function ResetPassword() {
  const { setUser } = useAppContext();
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showRequest, setShowRequest] = useState(true);
  const history = useHistory();
  const alert = useAlert();

  const handleRequestReset = (values, { setSubmitting }) => {
    Auth.forgotPassword(values.email)
      .then((resp) => {
        setErrorMsg(null);
        setShowRequest(false); // Show new password form now
        setUsername(values.email);
        const { Destination } = resp.CodeDeliveryDetails;
        alert.info(`Password reset code sent to ${Destination}.`);
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  const handleConfirmReset = (values, { setSubmitting }) => {
    Auth.forgotPasswordSubmit(username, values.code, values.password)
      .then(() => {
        // Successfully reset password - authenticate user
        setSubmitting(false);
        Auth.signIn(username, values.password)
          .then((respUser) => {
            setUser(respUser); // CognitoUser
            history.push("/");
            alert.success("Reset your password");
          })
          .catch((e) => {
            setErrorMsg(e.message);
            setSubmitting(false);
            console.error(e);
          });
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  return showRequest ? (
    <ResetPasswordRequestForm
      handleSubmit={handleRequestReset}
      errorMsg={errorMsg}
    />
  ) : (
    <ResetPasswordConfirmForm
      handleSubmit={handleConfirmReset}
      errorMsg={errorMsg}
    />
  );
}
