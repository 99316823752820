// Change password, as an authenticated user

import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useAlert } from "react-alert";
import { useAppContext } from "../common/AppContext";

import ProfileLayout from "./ProfileLayout";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoaderButton from "../components/LoaderButton";
import FormField from "../components/FormField";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  old_password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  new_password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  new_password2: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

export default function Profile() {
  const { user } = useAppContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const alert = useAlert();

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    Auth.changePassword(user, values.old_password, values.new_password)
      .then(() => {
        alert.success("Changed your password");
        setErrorMsg(null);
        setSubmitting(false);
        resetForm();
      })
      .catch((e) => {
        setErrorMsg(e.message);
        setSubmitting(false);
        console.error(e);
      });
  };

  return (
    <ProfileLayout>
      <h4 className="border-bottom pb-2 mb-4">Change Password</h4>
      <Formik
        initialValues={{
          old_password: "",
          new_password: "",
          new_password2: "",
        }}
        validationSchema={validation}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormField
              name="old_password"
              type="password"
              label="Old password"
              placeholder="Old password"
              icon={faLock}
            />
            <FormField
              name="new_password"
              type="password"
              label="New password"
              placeholder="New password"
              icon={faLock}
            />
            <FormField
              name="new_password2"
              type="password"
              label="Confirm new password"
              placeholder="Confirm new password"
              icon={faLock}
            />
            <LoaderButton
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              className="mt-3"
            >
              Change Password
            </LoaderButton>
            {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
          </Form>
        )}
      </Formik>
    </ProfileLayout>
  );
}
