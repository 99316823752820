import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class HomePage extends Component {
  render() {
    return (
      <>
        <h1>Not Found</h1>
        <p>
          We couldn't find the page you were looking for.{" "}
          <NavLink to="/">Click here</NavLink> to go back to the home page.
        </p>
      </>
    );
  }
}
