import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default function LoaderButton({
  isLoading = false,
  disabled = false,
  ...props
}) {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      <div className="d-flex justify-content-center align-items-center">
        {isLoading && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            className="mr-2"
          />
        )}
        <span>{props.children}</span>
      </div>
    </Button>
  );
}
