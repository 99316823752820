// Auth flow form to login existing user

import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormField from "../components/FormField";
import LoaderButton from "../components/LoaderButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

// Request reset password

const requestValidation = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
});

export function ResetPasswordRequestForm({ handleSubmit, errorMsg }) {
  return (
    <Row className="justify-content-md-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Reset Password</h4>
        <div className="mt-2 mb-4">
          Forgotten your password? Enter your email address below, and we'll
          send you an email allowing you to reset it.
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={requestValidation}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="email"
                type="email"
                placeholder="Email address"
                icon={faEnvelope}
                autoFocus={true}
              />
              <LoaderButton type="submit" isLoading={isSubmitting}>
                Request Password Reset
              </LoaderButton>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}

// Confirm reset password (enter code, new password)

const confirmValidation = Yup.object({
  code: Yup.number().typeError("Code is a number").required("Required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  password2: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export function ResetPasswordConfirmForm({ handleSubmit, errorMsg }) {
  return (
    <Row className="justify-content-md-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Reset Password</h4>
        <div className="mt-2 mb-4">
          We have sent a reset code to your email. Please enter it here,
          together with your new password:
        </div>
        <Formik
          initialValues={{ code: "", password: "", password2: "" }}
          validationSchema={confirmValidation}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="code"
                type="text"
                placeholder="Reset code"
                icon={faLock}
                autoFocus={true}
              />
              <FormField
                name="password"
                type="password"
                placeholder="New password"
                icon={faLock}
              />
              <FormField
                name="password2"
                type="password"
                placeholder="Confirm new password"
                icon={faLock}
              />
              <LoaderButton type="submit" isLoading={isSubmitting}>
                Reset Password
              </LoaderButton>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
