// Auth flow form to login existing user

import React from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderButton from "../components/LoaderButton";
import FormField from "../components/FormField";
import { faEnvelope, faUser, faLock } from "@fortawesome/free-solid-svg-icons";

const validation = Yup.object({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required"),
  password2: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default function LoginForm({ handleSubmit, errorMsg }) {
  return (
    <Row className="justify-content-md-center">
      <Col lg={6} md={8}>
        <h4 className="border-bottom pb-2 mb-2">Sign Up</h4>
        <div className="mt-2 mb-4">
          Sign up as a new user with your details:
        </div>
        <Formik
          initialValues={{ name: "", email: "", password: "", password2: "" }}
          validationSchema={validation}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                name="name"
                type="text"
                placeholder="Name"
                icon={faUser}
                autoFocus={true}
              />
              <FormField
                name="email"
                type="email"
                placeholder="Email address"
                icon={faEnvelope}
              />
              <FormField
                name="password"
                type="password"
                placeholder="Password"
                icon={faLock}
              />
              <FormField
                name="password2"
                type="password"
                placeholder="Confirm password"
                icon={faLock}
              />
              <LoaderButton
                type="submit"
                size="lg"
                block
                isLoading={isSubmitting}
              >
                Sign Up
              </LoaderButton>
              {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
}
