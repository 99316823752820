import React from "react";
import Container from "react-bootstrap/Container";
import { Provider as AlertProvider } from "react-alert";
import Alert, { alertOptions } from "../common/Alert";

import AppNavBar from "./AppNavBar";

export default function AppLayout(props) {
  const [navExpanded, setNavExpanded] = React.useState(false);
  const toggleNav = () => setNavExpanded(!navExpanded);
  const hideNav = () => setNavExpanded(false);

  return (
    <AlertProvider template={Alert} {...alertOptions}>
      <AppNavBar
        navExpanded={navExpanded}
        toggleNav={toggleNav}
        hideNav={hideNav}
      />
      <Container className="pt-3 h-100" onClick={hideNav}>
        {props.children}
      </Container>
    </AlertProvider>
  );
}
