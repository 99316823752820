import React from "react";
import Container from "react-bootstrap/Container";
import NavBar from "react-bootstrap/NavBar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

import { useAppContext } from "../common/AppContext";
import Logout from "../user/Logout";

export default function AppNavBar({ navExpanded, toggleNav, hideNav }) {
  const { user } = useAppContext(); // user is CognitoUser
  const userName = user?.attributes?.name;

  return (
    <header>
      <NavBar
        fixed="top"
        bg="light"
        variant="light"
        expand="sm"
        expanded={navExpanded}
        onToggle={toggleNav}
      >
        <Container>
          <NavLink to="/" className="navbar-brand">
            Sparo
          </NavLink>
          <NavBar.Toggle aria-controls="responsive-navbar-nav" />
          <NavBar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavLink to="/" exact onClick={hideNav} className="nav-link">
                Home
              </NavLink>
              <NavLink to="/pings" exact onClick={hideNav} className="nav-link">
                View Pings
              </NavLink>
            </Nav>
            <Nav>
              {user ? (
                <>
                  <NavBar.Text className="mr-4 d-none d-md-block">
                    {userName ? `Welcome ${userName}!` : "Welcome!"}
                  </NavBar.Text>
                  <NavLink to="/profile" onClick={hideNav} className="nav-link">
                    Profile
                  </NavLink>
                  <Logout as={Nav.Link}>Logout</Logout>
                </>
              ) : (
                <>
                  <NavLink to="/login" onClick={hideNav} className="nav-link">
                    Login
                  </NavLink>
                  <NavLink to="/signup" onClick={hideNav} className="nav-link">
                    Sign Up
                  </NavLink>
                </>
              )}
            </Nav>
          </NavBar.Collapse>
        </Container>
      </NavBar>
    </header>
  );
}
