import React, { useEffect, useRef } from "react";
import { LoadScript, GoogleMap } from "@react-google-maps/api";

import { GOOGLE_MAPS_API_KEY } from "../config";

const MAP_STYLE = {
  position: "absolute",
  width: "100%",
  height: "100%",
};
const MAP_OPTIONS = {
  mapTypeControl: true,
  fullscreenControl: false,
  streetViewControl: false,
};
const FIT_BOUNDS_PADDING = 100;

export default function Map({ children, bounds, ...props }) {
  // Store ref to underlying Google Map
  const mapRef = useRef(null);
  const handleLoad = (map) => (mapRef.current = map);

  // Determine map bounds
  useEffect(() => {
    if (mapRef.current && bounds) {
      mapRef.current.fitBounds(bounds, FIT_BOUNDS_PADDING);
    }
  }, [bounds, mapRef]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        center={{ lat: 0, lng: 0 }}
        zoom={2}
        onLoad={handleLoad}
        mapContainerStyle={MAP_STYLE}
        options={MAP_OPTIONS}
        {...props}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
}
